import React, {useEffect, useRef, useState} from "react"
import {connect, useSelector} from "react-redux"
import {useImmer} from "use-immer"
import OrderDishPage from "../dish/OrderDishPage"
import OrderComboDishPage from "../dish/OrderComboDishPage"
import {useTranslation} from "react-i18next"
import Dish from "./Dish"
import tagV from "../../images/tag_V.svg"
import "./MainPage.scss"
import dinnerIcon from "../../images/dinner_icon.svg"
import lunchIcon from "../../images/lunch_icon.svg"
import breakfastIcon from "../../images/breakfast_icon.svg"
import timerIcon from "../../images/timer_icon.svg"
import {
    setSearchText,
    setSearchDate,
    setSearchUser,
    setSearchLocation,
    setSearchMealType,
    setSearchConfiguration
} from "../../redux/actions/searchActions"
import moment from "moment"
import LoadingPage from "../LoadingPage"
import imageFiller from "../../images/image_filler_icon.svg"
import selectedIconRed from "../../images/selected_icon_red.svg"
import editIcon from "../../images/edit_icon.svg"
import deleteIcon from "../../images/delete_icon.svg"
import closeRed from "../../images/close-red-icon.svg"
import Tabs from "../Tabs"
import ConfirmationWindow from "../ConfirmationWindow"
import questionOctagonIcon from "../../images/dish/question_octagon_icon.svg"
import {Trans} from "react-i18next"
import LogoFooter from "./LogoFooter"
import {setError} from "../../redux/actions/errorHandlerActions"
import Footer from "../footer/Footer"
import NoResultsFiller from "../NoResultsFiller"
import {withRouter} from "react-router"
import {
    saveTempItemToOrder,
    incCount
} from "../../redux/actions/cartActions.js"
import {
    deleteOrder,
    deleteComboOrder,
    getStoreMealInfo,
    getUserInfo,
    getUserInfoWithoutId,
    getUserSettings,
    getUserBudget,
    getUserMealInfo,
    getUserSpecialDateInfo,
    GetUserMealInfoItem,
    saveItemToTempStorage,
    updateOrder,
    updateComboOrder,
    updateFavorites,
    getPriceRanges,
} from "./mainCrud"
import {
    formatDateForApi,
    formatDateWithWeekDay,
    ignoreTimeZone,
    ignoreTimeZoneStr,
    formatTime
} from "../commonFunctions"
import DropdownPanel from "../configuration/DropdownPanel"
import axios from "axios"
import Calendar from "../history/Calendar";
import calendarIcon from "../../images/history/calendar_icon_filled.svg";
import PushWindow from "../PushWindow";
import {useLocation} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel'
import {setSuccess} from "../../redux/actions/successHandlerActions";
import MessageCard from "../MessageCard";
import SummaryMobile from "../summary/mobile/SummaryMobile";
import {logout} from "../../redux/actions/loginActions";
import ComboDish from "./ComboDish";

const PAGINATION_DESKTOP = 20;
const PAGINATION_MOBILE = 10;

function MainPage(props) {
    const { showContactUs, setShowContactUs } = props;

    const favouriteHandler = (el, favoriteItemsKey, fieldKey) => {
        let allFavoriteItems = [];
        let favoriteIsChecked = false;
        if (localStorage.getItem(favoriteItemsKey) != (null || "null")) {
            allFavoriteItems = JSON.parse(localStorage.getItem(favoriteItemsKey));
            if (allFavoriteItems) allFavoriteItems.map((item, key) => {
                if (item == el[fieldKey]) {
                    allFavoriteItems.splice(key, 1);
                    favoriteIsChecked = true
                }
            });
        }
        if (!favoriteIsChecked && allFavoriteItems) {
            allFavoriteItems.push(el[fieldKey]);
        }
        if (!allFavoriteItems) {
            allFavoriteItems = []
            allFavoriteItems.push(el[fieldKey]);
        }
        localStorage.setItem(favoriteItemsKey, JSON.stringify(allFavoriteItems));
        setFavoriteItems(JSON.parse(localStorage.getItem(favoriteItemsKey)));
        const data = {};
        data[favoriteItemsKey] = JSON.stringify(localStorage.getItem(favoriteItemsKey))
        updateFavorites(data);
    }

    const STORE_TYPE = 4;
    const location = useLocation();

    const { t, i18n } = useTranslation("main");
    const {innerWidth: width} = window
    const initialFrom = moment().add(-1, "day")
    const showStoreMeals = props.location.pathname === "/store"
    const isRoleDepartmentUserOrAdmin = ["Department User", "Admin"].includes(
        props.userInfo?.Role
    )

    const isMobile = () => width < 1024;

    useEffect(() => {
        if (!props.userInfo) {
            logout();
        }
    }, [props.userInfo])

    const [selectedRangeForItems, setSelectedRangeForItems] = useState({
        from: initialFrom,
        to: moment().add(0, "day")
    })
    const [showCalendar, setShowCalendar] = useState(false)
    const [state, setState] = useImmer({
        currentComboId: null,
        currentStoreCombo: null,
        editCombo: null,
        currentItemId: null,
        currentStoreItem: null,
        dailyBudget: null,
        specialDate: null,
        isPriceDisplayed: false,
        scrollPosition: 0,
        currentType: [],
        currentTags: [],
        currentSuppliers: [],
        users: [],
        locations: [],
        dates: [],
        dishes: [],
        currentDishComboAndItems: [],
        currentDishComboAndItemsTotal: 0,
        dishesCombos: [],
        storeDishes: [],
        storeDishesCombos: [],
        tags: [],
        favouriteTags: [],
        storeTags: [],

        suppliers: [],
        favouriteSuppliers: [],
        storeSuppliers: [],

        isLoading: true,
        isStoreLoading: false,
        countdown: "",
        showImg: false,
        showImgUrl: "",
        orderId: null,

        editItem: null,

        closeOrderTime: null,
        closeEditTime: null,
        closeCancelTime: null,
        priceRanges: [],
        currentPriceRanges: [],
        showFilterPanel: false,
        cancelOrderConfirmation: {show: false, OrderId: "", OrderItemID: ""},
        pushNotification: {show: false},
        dropDownExpanded: false
    })

    useEffect(() => {
        let elements =
            filterComboDishes(state.dishesCombos).concat(filterDishes(state.dishes));
        elements = elements.sort((el1, el2) => {
            const element1OrderAvailable = isOrderTimeOver ||
                (moment(el1.CloseOrderTime).format("HH:mm:ss") !==
                    "00:00:00" &&
                    moment(el1.CloseOrderTime).isBefore());
            const element2OrderAvailable = isOrderTimeOver ||
                (moment(el2.CloseOrderTime).format("HH:mm:ss") !==
                    "00:00:00" &&
                    moment(el2.CloseOrderTime).isBefore());
            return element1OrderAvailable - element2OrderAvailable;
        })
        if (elements.length) {
            const items = [];
            if (elements.length !==
                state.currentDishComboAndItemsTotal) {
                const items = getMealItems(elements);
                setState((draft) => {
                    draft.currentDishComboAndItems = items;
                    draft.currentDishComboAndItemsTotal = items.length
                })
            }
        }
    }, [
        window.scrollY,
        state.dishesCombos,
        state.dishes,
        state.currentSuppliers,
        state.currentPriceRanges,
        state.currentTags
    ])

    useEffect(() => {
        let elements = filterComboDishes(state.dishesCombos)
            .concat(filterDishes(state.dishes))
            .slice(0, (isMobile() ? PAGINATION_MOBILE : PAGINATION_DESKTOP));
        
        setState((draft) => {
            draft.currentDishComboAndItems = elements.map(el => ({ ...el }));
            draft.currentDishComboAndItemsTotal = elements.length
        });
    }, [
        props.searchText
    ])

    const getMealItems = (elements) => {
        return state.currentDishComboAndItems.slice()
            .concat(elements.slice(
                    state.currentDishComboAndItemsTotal,
                    (state.currentDishComboAndItemsTotal + (isMobile() ? PAGINATION_MOBILE : PAGINATION_DESKTOP))
                )
        )
    }

    const [deliveryTime, setDeliveryTime] = useState()
    const [deliveryStoreTime, setDeliveryStoreTime] = useState()
    const [selectedMeal, setSelectedMeal] = useState(undefined);
    const [selectedStoreMeal, setSelectedStoreMeal] = useState(undefined);
    const calendarWindow = useRef(null)
    const [calendarWindowContainer, setCalendarWindowContainer] = useState(null)
    const mealTypes = [
        {
            ItemId: 1,
            name: t("order_item.meal_type.breakfast"),
            icon: breakfastIcon
        },
        {ItemId: 2, name: t("order_item.meal_type.lunch"), icon: lunchIcon},
        {ItemId: 3, name: t("order_item.meal_type.dinner"), icon: dinnerIcon}
    ]

    const [favoriteItems, setFavoriteItems] = useState(JSON.parse(localStorage.getItem("favoriteItems") || "[]"));
    const [walletBalance, setWalletBalance] = useState(null)
    const [banners, setBanners] = useState([]);
    const [budget, setBudget] = useState(undefined);
    const [priceRanges, setPriceRanges] = useState([]);

    const showBalance = state.dailyBudget !== null || walletBalance !== null

    function fetchPriceRanges(locationID) {
        getPriceRanges(locationID)
            .then(({data}) => {
                setState(draft => { draft.priceRanges = data})
                setPriceRanges(
                    data
                )
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
    }

    let pushDate;

    function closeCalendar() {
        setShowCalendar(false)
        document.removeEventListener("click", handleCloseCalendar)
    }

    function sortMealItem(MealItems) {
        let allFavoriteItems = JSON.parse(localStorage.getItem("favoriteItems"));
        if (MealItems.length > 0 && allFavoriteItems) MealItems.sort((a) => {
            return (allFavoriteItems.includes(a.ItemId)) ? -1 : 1
        })
    }

    function updateStoreDishes(selectedMeal, date) {
        setState(draft => {
            draft.isStoreLoading = true
        })
        const cancelToken = axios.CancelToken.source()
        getStoreMealInfo(
            props.searchUser.UserID,
            selectedMeal.id,
            date ? formatDateForApi(date) : undefined,
            deliveryStoreTime?.Id,
            cancelToken.token
        )
            .then(({data}) => {
                let tags = []
                data.MealItems.forEach(mealItem => {
                    mealItem.Tags.forEach(tag => {
                        if (!tags.find(el => el.TagID === tag.TagID)) {
                            tags.push(tag)
                        }
                    })
                    mealItem.ImageTags.forEach(tag => {
                        if (!tags.find(el => el.TagID === tag.TagID)) {
                            tags.push(tag)
                        }
                    })
                })
                data.MealItems.sort((a, b) => b.Priority - a.Priority)


                const favouriteTags = [...selectedMeal.MenuFavoritTags]
                if (favouriteTags.length != 0) favouriteTags.sort((a, b) => b.Priority - a.Priority)



                let suppliersObj = {};
                let suppliers = [];
                data.MealItems.forEach(mealItem => {
                    suppliersObj[mealItem.SupplierID] = {
                        SupplierName: mealItem.SupplierName,
                        SupplierID: mealItem.SupplierID,
                        SupplierLogo: mealItem.SupplierLogo,
                    };
                });
                Object.keys(suppliersObj).map(t => suppliersObj[t]);



                setState(draft => {
                    draft.storeDishes = data.MealItems
                    draft.storeDishesCombos = data.MealItemsCombos
                    draft.storeTags = tags
                    draft.storeSuppliers = suppliers

                    draft.storeUserOrder = data.UserOrder
                    draft.storeFavouriteTags = favouriteTags
                    draft.storeCloseOrderTime = data.CloseOrderTime
                    draft.storeCloseEditTime = data.CloseEditTime
                    draft.storeCloseCancelTime = data.CloseCancelTime

                    draft.isStoreLoading = false
                })

            })
            .catch(error => {
                console.error(error)
                props.setError(
                    error,
                    t("main.api_errors.failed_to_get_store_meal_info")
                )
            })
        return () => cancelToken.cancel()
    }

    function handleCloseCalendar(event) {
        if (!calendarWindowContainer.contains(event.target)) {
            closeCalendar()
        }
    }

    function handleRedirectToOrders() {
        props.history.push("/order")
      }
    function onClickItem(index) {
        if (banners[index].type == 0)
            setState(draft => {
                draft.showImg = true
                draft.showImgUrl = banners[index].desktopImageUrl
            })
        onClickMobileItem(index);
    }

    function onClickMobileItem(index) {
        if (banners[index].type == 2) {
            const dish = state.dishes.find(item => item.ItemId === banners[index].itemID);
            if (dish) {
                openInfoWindow(dish.ItemId, false, dish);
            } else {
                const storeDish = state.storeDishes
                    .find(item => item.ItemId === banners[index].itemID);
                if (storeDish) {
                    const orderDisabled =
                        isOrderTimeOverStore ||
                        (moment(storeDish.CloseOrderTime).format("HH:mm:ss") !==
                            "00:00:00" &&
                            moment(storeDish.CloseOrderTime).isBefore())
                    openInfoWindow(storeDish.ItemId, true, storeDish, orderDisabled);
                }
            }
        }
        banners[index].type == 1 && window.open(banners[index].url)
    }

    useEffect(() => {
        if (!calendarWindowContainer) {
            setCalendarWindowContainer(calendarWindow.current)
        }
    }, [calendarWindow.current])


    function isLastMealTimeOver(location) {
        if (!location.Meals.length) {
            return false
        }
        const latestMeal = location.Meals.reduce(
            (latestMeal, meal) =>
                meal.MealType > latestMeal.MealType && meal.MealType !== STORE_TYPE
                    ? meal
                    : latestMeal,
            {MealType: 0}
        )

        return moment(latestMeal.DeliveryMaxTime, "HH:mm:ss").isBefore()
    }

    const cart = useSelector(state => state.cart)

    function getId() {
        return props.userInfo.Role !== "Admin"
            ? props.userInfo.UserID
            : props.adminUsers.length && props.adminUsers[0].UserID;
    }

    useEffect(() => {
        const id = getId();

        if (id) {
            const cancelToken = axios.CancelToken.source()
            getUserInfoWithoutId(cancelToken.token).then(({data}) => {
                if (data.UserInfo?.WalletBalance) {
                  setWalletBalance(data.UserInfo.WalletBalance)
                }
                if (data.UserInfo.FavouriteItems) {
                    localStorage.setItem("favoriteItems", JSON.parse(data.UserInfo.FavouriteItems));
                    setFavoriteItems(JSON.parse(localStorage.getItem("favoriteItems")));
                } else setFavoriteItems([])
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message );
            });

            loadInfo(id, cancelToken);

            getUserSettings(cancelToken.token)
                .then(({data}) => {
                    var filteredData = data.banner.map((banner) => {
                        banner.show = true;
                        return banner;
                    });
                    setBanners(filteredData);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error(error)
                        props.setError(error, t("main.api_errors.failed_to_get_user_settings"))
                    }
                })
            if (props.searchDate!=''){
                getUserBudget(id, props.searchDate.slice(0, -6), cart[id] ? cart[id].orderID : undefined, cancelToken.token)
                .then(({data}) => {
                    setBudget(data);
                })
                .catch(error => {
                    props.setError(error, error?.response?.data?.Message );
                })
            return () => cancelToken.cancel()
            }    
        }
    }, [props.adminUsers])

    function loadInfo(id, cancelToken) {
        if (id) {
            getUserInfo(id, cancelToken.token)
                .then((response) => {
                    if (!response) {
                        logout();
                        return;
                    }
                    const data = response?.data;
                    const selectedLocation = data.Locations.length
                        ? data.Locations[0]
                        : null
                    // const availableDates = isLastMealTimeOver(selectedLocation)
                    //     ? data.OrderDates.filter(el => !moment().isSame(el.Date, "day"))
                    //     : data.OrderDates
                    const availableDates = data.OrderDates;
                    if (selectedLocation && (!selectedLocation.Meals || selectedLocation.Meals.length === 0)) {
                        setState(draft => {
                            draft.isLoading = false
                        })
                    }

                    const orderForUsers =
                        props.userInfo.Role !== "Admin"
                            ? data.OrderForUsers
                            : props.adminUsers
                    setState(draft => {
                        draft.users = orderForUsers
                        draft.isPriceDisplayed = data.IsPriceDisplayed
                        draft.locations = data.Locations
                        draft.dates = availableDates.map((el, index) => ({
                            ...el,
                            ItemId: index + 1
                        }))
                    })

                    let dateDisplay;
                    if (pushDate) {
                        let field = '';
                        switch (pushDate.mealType) {
                            case 1: {
                                field = 'Breakfast';
                            }
                            case 2: {
                                field = 'Dinner';
                            }
                            case 3: {
                                field = 'Lunch';
                            }
                        }
                        const itemDate = availableDates.filter(it => it.Date === pushDate.date && it[field]);
                        if (itemDate[0]) {
                            dateDisplay = itemDate[0].Date;
                        }
                    }

                    const user =
                        orderForUsers.find(
                            user => user.UserID === props.searchUser.UserID
                        ) ||
                        (isRoleDepartmentUserOrAdmin
                            ? orderForUsers.find(
                                user => user.UserID !== props.userInfo.UserID
                            )
                            : {
                                UserID: props.userInfo.UserID,
                                Name: `${props.userInfo.FirstName} ${props.userInfo.LastName}`
                            })
                    const orderDate = data.OrderDates.find(
                        el => el.Date === props.searchDate
                    )
                        ? props.searchDate
                        : availableDates[0].Date
                    const mealObj = data.Locations.length && selectedLocation.Meals.length
                        ? selectedLocation.Meals[0]
                        : null;
                    const mealType =
                        mealObj ? mealObj.MealType : null
                    props.setSearchConfiguration({
                        user,
                        location: selectedLocation,
                        date: dateDisplay ? dateDisplay : orderDate,
                        mealType
                    })
                    setDeliveryInfo(mealObj);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error(error)
                        props.setError(error, t("main.api_errors.failed_to_get_user_info"))
                    }
                })
        }

    }

    useEffect(() => {
        setState(draft => {
            draft.currentTags = []
            draft.currentSuppliers = []
            draft.storeDishes = []
            draft.storeDishesCombos = []
            draft.dishes = []
            draft.currentDishComboAndItems = [];
            draft.currentDishComboAndItemsTotal = 0;
            draft.dishesCombos = []
        })
    }, [showStoreMeals, props.searchMealType, props.searchDate, deliveryTime?.Id, deliveryStoreTime?.Id])

    useEffect(() => {
        loadMeals();
    }, [props.searchLocation, props.searchDate, props.searchMealType, showStoreMeals, deliveryTime?.Id, deliveryStoreTime?.Id])

    useEffect(() => {
        if (props.searchLocation && showStoreMeals) {
            const mealObj = props.searchLocation.Meals.find(item => item.MealType === STORE_TYPE);
            setSelectedStoreMeal(mealObj)
            setDeliveryStoreTime((mealObj && mealObj.DeliveryTimes) ? mealObj.DeliveryTimes[0] : null)
        }
     }, [showStoreMeals, props.searchLocation])

    useEffect(() => {
        if (props.searchLocation) {
            const mealObj = props.searchLocation.Meals.length
                ? props.searchLocation.Meals.find(m => m.MealType === props.searchMealType)
                : null;
            setDeliveryTime((mealObj && mealObj.DeliveryTimes) ? mealObj.DeliveryTimes[0] : null)
        }
    }, [props.searchMealType])

    function loadMeals() {
        if (
            props.searchLocation &&
            props.searchLocation.Meals &&
            props.searchDate &&
            props.searchUser.UserID
        ) {
            if (props.searchLocation.Meals.length) {
                const selectedMeal = props.searchLocation.Meals.find(
                    mealInfo => mealInfo.MealType === (showStoreMeals ? STORE_TYPE : props.searchMealType)
                )
                if (!selectedMeal) {
                    return
                }
                if (selectedMeal.MealType === STORE_TYPE) {
                    updateStoreDishes(selectedMeal, props.searchDate);
                    return;
                }
                setState(draft => {
                    draft.isLoading = true
                })
                const cancelToken = axios.CancelToken.source()
                getUserSpecialDateInfo(props.searchUser.UserID,
                    selectedMeal.id,
                    formatDateForApi(ignoreTimeZoneStr(props.searchDate)),
                    cancelToken.token)
                    .then(({data}) => {
                        setState(draft => {
                            draft.specialDate = data
                        })
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error)
                            props.setError(
                                error,
                                t("main.api_errors.failed_to_get_user_special_date_info")
                            )
                        }
                    })
                getUserMealInfo(
                    props.searchUser.UserID,
                    selectedMeal.id,
                    formatDateForApi(ignoreTimeZoneStr(props.searchDate)),
                    cart[props.searchUser.UserID]?.orderID,
                    deliveryTime?.Id,
                    cancelToken.token
                )
                    .then(({data}) => {
                        let tags = []
                        data.MealItems.forEach(mealItem => {
                            mealItem.Tags.forEach(tag => {
                                if (!tags.find(el => el.TagID === tag.TagID)) {
                                    tags.push(tag)
                                }
                            })
                            mealItem.ImageTags.forEach(tag => {
                                if (!tags.find(el => el.TagID === tag.TagID)) {
                                    tags.push(tag)
                                }
                            })
                        })
                        tags.sort((a, b) => b.Priority - a.Priority)
                        data.MealItems.sort((a, b) => b.Priority - a.Priority)

                        sortMealItem(data.MealItems);
                        const favouriteTags = [...selectedMeal.MenuFavoritTags]
                        favouriteTags.sort((a, b) => b.Priority - a.Priority)

                        let suppliersObj = {};
                        let suppliers = [];
                        data.MealItems.forEach(mealItem => {
                            suppliersObj[mealItem.SupplierID] = {
                                SupplierName: mealItem.SupplierName,
                                SupplierID: mealItem.SupplierID,
                                SupplierLogo: mealItem.SupplierLogo,
                            };
                        });
                        suppliers = Object.keys(suppliersObj).map(t => suppliersObj[t]);
                        setState(draft => {
                            draft.dailyBudget = data.DailyBudget
                            draft.dishes = data.MealItems
                            draft.dishesCombos = data.MealCombos
                            draft.currentDishComboAndItems = [];
                            draft.currentDishComboAndItemsTotal = 0;
                            draft.subsidyLimit = data.SubsidyLimit
                            draft.tags = tags
                            draft.suppliers = suppliers
                            draft.userOrder = data.UserOrder
                            draft.favouriteTags = favouriteTags
                            draft.closeOrderTime = data.CloseOrderTime
                            draft.closeEditTime = data.CloseEditTime
                            draft.closeCancelTime = data.CloseCancelTime
                            draft.isLoading = false
                        })
                        if (pushDate && pushDate.ItemId) {
                            openInfoWindow(pushDate.ItemId);
                        }
                        pushDate = undefined;

                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error)
                            props.setError(
                                error,
                                t("main.api_errors.failed_to_get_user_meal_info")
                            )
                        }
                    })
                return () => cancelToken.cancel()
            }
        }
    }

    useEffect(() => {
        if (
            props.searchLocation &&
            props.searchLocation.Meals &&
            props.searchUser.UserID
        ) {
            if (!props.searchLocation.Meals || props.searchLocation.Meals.length) {
                setState(draft => {
                    draft.isLoading = false
                })
                
            }
            const selectedMeal = props.searchLocation.Meals.find(
                mealInfo => mealInfo.MealType === STORE_TYPE
            )
            fetchPriceRanges(props.searchLocation.LocationID)
            if (selectedMeal) {
                return updateStoreDishes(selectedMeal);
            } else {
                setState(draft => {
                    draft.storeDishes = []
                    draft.storeDishesCombos = []
                    draft.storeTags = []
                    draft.storeSuppliers = []
                })
            }
        }
    }, [props.searchLocation])

    useEffect(() => {
        if (state.datesExpanded) {
            document.addEventListener("click", closeDropdown)
        }
    }, [state.datesExpanded])

    function handleExpand(targetName) {
        switch (targetName) {
            case "date":
                setState(draft => {
                    draft.datesExpanded = !state.datesExpanded
                    draft.dropDownExpanded = !state.datesExpanded
                })
                break
            default:
        }
    }

    useEffect(() => {
        if (
            window.innerWidth < 1024 &&
            state.scrollPosition &&
            state.currentItemId === null &&
            state.editItem === null
        ) {
            window.scrollTo({top: state.scrollPosition, behavior: "instant"})
        }
    }, [state.currentItemId, state.editItem])

    function closeDropdown(event) {
        event.preventDefault()
        setState(draft => {
            draft.datesExpanded = false
            draft.dropDownExpanded = false
        })
        document.removeEventListener("click", closeDropdown)
    }

    function editComboOrder(data) {
        return updateComboOrder(
            props.searchUser.UserID,
            state.editCombo.OrderId,
            state.editCombo.OrderComboID,
            data
        )
    }

    function editOrder(data) {
        return updateOrder(
            props.searchUser.UserID,
            state.editItem.OrderId,
            state.editItem.OrderItemID,
            data
        )
    }

    function confirmEditComboOrder(data) {
        setState(draft => {
            const index = state.userOrder.findIndex(
                el => el.OrderComboID === state.editCombo.OrderComboID
            )
            draft.userOrder[index] = {
                ...draft.userOrder[index],
                OrderItemNotes: data.notes,
                Items: data.Items
            }
        })
    }

    function confirmEditOrder(data) {
        setState(draft => {
            const index = state.userOrder.findIndex(
                el => el.OrderItemID === state.editItem.OrderItemID
            )
            draft.userOrder[index] = {
                ...draft.userOrder[index],
                SelectedOptions: data.options,
                OrderItemNotes: data.notes
            }
        })
    }

    function cancelOrder(orderID, itemID, comboId) {
        if (comboId) {
            deleteComboOrder(props.searchUser.UserID, orderID, comboId)
                .then(() => {
                    setState(draft => {
                        draft.userOrder = state.userOrder.filter(
                            el => el.OrderId !== orderID || el.OrderComboID !== comboId
                        )
                    })
                    loadMeals();
                    loadInfo(getId(), axios.CancelToken.source())
                })
                .catch(error => {
                    console.error(error)
                    props.setError(error, t("main.api_errors.failed_to_cancel_order"))
                })
            return;
        }
        deleteOrder(props.searchUser.UserID, orderID, itemID)
            .then(() => {
                setState(draft => {
                    draft.userOrder = state.userOrder.filter(
                        el => el.OrderId !== orderID || el.OrderItemID !== itemID
                    )
                })
                loadMeals();
                loadInfo(getId(), axios.CancelToken.source())
            })
            .catch(error => {
                console.error(error)
                props.setError(error, t("main.api_errors.failed_to_cancel_order"))
            })
    }

    function saveTempMealItemToOrder(orderItem) {
        saveItemToTempStorage(
            props.searchUser.UserID,
            props.cart[props.searchUser.UserID]
                ? props.cart[props.searchUser.UserID].orderID
                : undefined,
            orderItem,
            showStoreMeals ? deliveryStoreTime?.Id : deliveryTime?.Id
        )
            .then(({data}) => {
                props.saveTempItemToOrder(props.searchUser.UserID, data.Id)
                props.incCount(props.searchUser.UserID, orderItem.Quantity, data.EmployeeSum)
                props.setSuccess({status: 1}, t("order_item.api_success.success_to_save_to_cart"))
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.Message || error.message;
                if (errorMessage === "Order Item Limit" || errorMessage === "Order item quantity is more than available") {
                    props.setError(error, t("order_item.api_errors.there_is_an_order_for_this_day_in_Cart"))
                } else if (errorMessage === "Invalid rating time") {
                    props.setError(error, t("order_item.api_errors.time_is_over"))
                } else {
                    props.setError(error, t("order_item.api_errors.failed_to_save_to_cart"))
                }
            })
    }

    function addStoreItemToCart(itemID, itemName, quantity) {
        const orderItem = {
            ItemID: itemID,
            SelectedOptions: [],
            Notes: "",
            Status: null,
            MealID: props.searchLocation.Meals.find(
                mealInfo => mealInfo.MealType === STORE_TYPE
            ).id,
            OrderToDate: formatDateForApi(props.searchDate),
            SupplierID: "",
            Quantity: quantity,
            MealDeliveryTimeId: showStoreMeals ? deliveryStoreTime?.Id : deliveryTime?.Id,
            ItemName: itemName
        }
        saveTempMealItemToOrder(orderItem)
    }

    function selectUser(id) {
        setState(draft => {
            draft.isLoading = true
        })
        getUserInfo(id).then(({data}) => {
            const selectedLocation = data.Locations.length ? data.Locations[0] : null
            // const availableDates = isLastMealTimeOver(selectedLocation)
            //     ? data.OrderDates.filter(el => !moment().isSame(el.Date, "day"))
            //     : data.OrderDates
            const availableDates = data.OrderDates
            setState(draft => {
                draft.isPriceDisplayed = data.IsPriceDisplayed
                draft.locations = data.Locations
                draft.dates = availableDates.map((el, index) => ({
                    ...el,
                    ItemId: index + 1
                }))
            })
            const mealObj = data.Locations.length && selectedLocation.Meals.length
                ? selectedLocation.Meals[0]
                : null;
            const mealType =
                mealObj ? mealObj.MealType : null
            props.setSearchConfiguration({
                user: state.users.find(user => user.UserID === id),
                location: selectedLocation,
                date: data.OrderDates.find(el => el.Date === props.searchDate)
                    ? props.searchDate
                    : availableDates[0].Date,
                mealType
            })
            setDeliveryInfo(mealObj);
        })
        .catch(error => {
            props.setError(error, error?.response?.data?.Message);
        })
    }

    function setDeliveryInfo(mealObj) {
        setSelectedMeal(mealObj)
        setDeliveryTime((mealObj && mealObj.DeliveryTimes) ? mealObj.DeliveryTimes[0] : null)
    }

    function selectDateWithObj(date) {
        const cancelToken = axios.CancelToken.source()
        getUserSettings(cancelToken.token)
            .then(({data}) => {
                var filteredData = data.banner.filter(banner => {
                    banner.show = true;
                    if (banner.availableFrom && banner.availableTo)
                        if ((date.slice(0, -6) > banner.availableTo) || (date.slice(0, -6) < banner.availableFrom))
                            return false
                    return banner.show
                })
                setBanners(filteredData);
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
        const id =
            props.userInfo.Role !== "Admin"
                ? props.userInfo.UserID
                : props.adminUsers.length && props.adminUsers[0].UserID
        getUserBudget(id, date.slice(0, -6), cart[id] ? cart[id].orderID : undefined, cancelToken.token)
            .then(({data}) => {
                setBudget(data);
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
        props.setSearchDate(date)
    }

    function selectMeal(id) {
        props.setSearchMealType(id)
    }

    function selectLocation(location) {
        props.setSearchLocation(location)
        updateMeal(location);
    }

    function updateMeal(selectedLocation) {
        const mealObj = selectedLocation.Meals[0]
        const mealType =
            mealObj ? mealObj.MealType : null
        setDeliveryInfo(mealObj);
    }

    function selectTag(id, isFavourite) {
        setState(draft => {
            let index = draft.currentTags.indexOf(id)
            if (index !== -1) {
                draft.currentTags.splice(index, 1)
            } else {
                isFavourite ? (draft.currentTags = [id]) : draft.currentTags.push(id)
            }
            draft.currentDishComboAndItems = [];
            draft.currentDishComboAndItemsTotal = 0;
        })
    }

    function selectPriceRange(el) {
        setState(draft => {
            let index = draft.currentPriceRanges.findIndex(q=> q.PriceRangeID == el.PriceRangeID)
            if (index !== -1) {
                draft.currentPriceRanges.splice(index, 1)
            } else {
                draft.currentPriceRanges.push(el)
            }
            draft.currentDishComboAndItems = [];
            draft.currentDishComboAndItemsTotal = 0;
        })
    }

    function openInfoWindow(id, isStoreItemId, item) {
        setState(draft => {
            draft.currentItemId = id
            draft.currentItem = item
            draft.currentStoreItem = isStoreItemId
            draft.scrollPosition = window.pageYOffset
        })
    }

    function handleSearchTextChange(event) {
        props.setSearchText(event.target.value)
    }

    function formatDate(date) {
        return formatDateWithWeekDay(ignoreTimeZone(date), true)
    }

    function filterComboDishes(items) {
        return items.filter(
            (el, index) =>
                (el.ComboName.includes(props.searchText) ) &&
                (state.currentTags.length
                    ? state.currentTags.every(id =>
                        el.Tags?.map(tag => tag.TagID).includes(id)
                    ) ||
                    state.currentTags.every(id =>
                        el.ImageTags?.map(tag => tag.TagID).includes(id)
                    )
                    : true)
                &&
                (state.currentPriceRanges.length ?
                    state.currentPriceRanges.some(id =>
                        el.FirstItemPrice >= id.PriceMin && el.FirstItemPrice <= id.PriceMax
                    ) : true)
        );;
    }

    function filterDishes(dishes) {
        const result = dishes.filter(
            (el, index) =>
                (el.ItemName.includes(props.searchText) ||
                    el.SupplierDisplayName.includes(props.searchText)) &&
                (state.currentTags.length
                    ? state.currentTags.every(id =>
                        el.Tags.map(tag => tag.TagID).includes(id)
                    ) ||
                    state.currentTags.every(id =>
                        el.ImageTags.map(tag => tag.TagID).includes(id)
                    )
                    : true)
                &&
                (state.currentPriceRanges.length ?
                    state.currentPriceRanges.some(id =>
                        el.FirstItemPrice >= id.PriceMin && el.FirstItemPrice <= id.PriceMax
                    ) : true)
        );
        if (state.currentSuppliers.length > 0) {
            return result.filter(el => state.currentSuppliers.indexOf(el.SupplierID) >= 0)
        }
        return result;
    }

    const showCountdown =
        state.closeOrderTime &&
        Date.parse(new Date(state.closeOrderTime)) - Date.parse(new Date()) <=
        3 * 3600000
    const isOrderTimeOver =
        state.closeOrderTime &&
        Date.parse(new Date(state.closeOrderTime)) - Date.parse(new Date()) <= 0

    // const isEditTimeOver =
    //     state.closeEditTime &&
    //     Date.parse(new Date(state.closeEditTime)) - Date.parse(new Date()) <= 0
    // const isCancelTimeOver =
    //     state.closeCancelTime &&
    //     Date.parse(new Date(state.closeCancelTime)) - Date.parse(new Date()) <= 0

    const showCountdownStore =
        state.storeCloseOrderTime &&
        Date.parse(new Date(state.storeCloseOrderTime)) - Date.parse(new Date()) <=
        3 * 3600000
    const isOrderTimeOverStore =
        state.storeCloseOrderTime &&
        Date.parse(new Date(state.storeCloseOrderTime)) - Date.parse(new Date()) <= 0

    // const isEditTimeOverStore =
    //     state.storeCloseEditTime &&
    //     Date.parse(new Date(state.storeCloseEditTime)) - Date.parse(new Date()) <= 0
    // const isCancelTimeOverStore =
    //     state.storeCloseCancelTime &&
    //     Date.parse(new Date(state.storeCloseCancelTime)) - Date.parse(new Date()) <= 0


    function renderBudget() {
        return (
            <>
                {budget ? (
                    <strong>
                        <div>{t("main.budget.balance")} {budget.dailyLimit || budget.balance}</div>
                        <div>{t("main.budget.inUse")} {budget.inUse}</div>
                    </strong>
                ) : (
                    <div></div>
                )}
            </>
        )
    }

    function renderCountdown(store) {
        if (store) {
            const closeTime = formatTime(state.storeCloseOrderTime)
            const countdown = state.countdown
            return (
                <>
                    <img src={timerIcon} alt="timer"/>
                    {isOrderTimeOverStore ? (
                        <span>
                            {t("main.countdown.overdue_start")}
                            <strong
                                className="cursor-pointer"
                                onClick={() => setShowContactUs(true)}>
                                {t("main.countdown.overdue_customer_service")}
                            </strong>
                            {t("main.countdown.overdue_end")}
                        </span>
                    ) : (
                        <Trans t={t} i18nKey="main.countdown.info">
                            Can be ordered up to {{closeTime}} |{" "}
                            <strong>Time left for order {{countdown}}</strong>
                        </Trans>
                    )}
                </>
            )
        }
        const closeTime = formatTime(state.closeOrderTime)
        const countdown = state.countdown
        return (
            <>
                <img src={timerIcon} alt="timer"/>
                {isOrderTimeOver ? (
                    <span>
                        {t("main.countdown.overdue_start")}
                        <strong
                            className="cursor-pointer"
                            onClick={() => setShowContactUs(true)}>
                            {t("main.countdown.overdue_customer_service")}
                        </strong>
                        {t("main.countdown.overdue_end")}
                    </span>
                ) : (
                    <Trans t={t} i18nKey="main.countdown.info">
                        Can be ordered up to {{closeTime}} |{" "}
                        <strong>Time left for order {{countdown}}</strong>
                    </Trans>
                )}
            </>
        )
    }

    function renderTagPanel() {
        const total = showStoreMeals
            ? filterDishes(state.storeDishes).length
            : filterDishes(state.dishes).length
        return (
            <div className="filter-panel" dir={i18n.dir()}>
                <div className="filter-panel__header">
                    <div className="filter-panel__header__title">
                        {t("main.filter_panel.title")}
                    </div>
                    <img
                        className="filter-panel__header__close-icon"
                        src={closeRed}
                        alt="Close icon"
                        onClick={() =>
                            setState(draft => {
                                draft.showFilterPanel = false
                            })
                        }
                    />
                </div>
                <div className="filter-panel__info">

                    {renderFilters(false, false)}
                    <div className="filter-panel__footer">
                        <div className="filter-panel__footer__available">
                            <Trans t={t} i18nKey="main.filter_panel.total_found">
                                {{total}} results found
                            </Trans>
                        </div>
                        <div
                            className="filter-panel__footer__confirm"
                            onClick={() =>
                                setState(draft => {
                                    draft.showFilterPanel = false
                                })
                            }
                        >
                            {t("main.filter_panel.submit_btn")}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderSuppliers(onlyFavouriteSuppliers) {
        const suppliers = showStoreMeals
            ? state.storeSuppliers
            : state.suppliers;
        return (
            <>
                <div className="list suppliers" style={{direction : i18n.dir()}}>
                <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>{t("main.suppliers")}</span>
                {
                    suppliers.map((el, index) => (
                        renderSupplier(el, onlyFavouriteSuppliers)
                    ))
                }
                </div>
            </>
        )
    }

    function renderFilters(onlyFavouriteTags, showSuppliers) {
        const tags = showStoreMeals
            ? state.storeTags
            : onlyFavouriteTags
                ? state.favouriteTags
                : state.tags;

        const suppliers = showStoreMeals
            ? state.storeSuppliers
            : state.suppliers;

        const tagsByGroupObj = {
            All: []
        };

        tags.forEach(tag => {
            if (tag) {
                if (tag.ParentTag) {
                    if (!tagsByGroupObj[tag.ParentTag.TagID]) {
                        tagsByGroupObj[tag.ParentTag.TagID] = [];
                    }
                    tagsByGroupObj[tag.ParentTag.TagID].push(tag);
                } else {
                    if (!tagsByGroupObj['All']) {
                        tagsByGroupObj['All'] = [];
                    }
                    tagsByGroupObj['All'].push(tag);
                }
            }

        })
        return onlyFavouriteTags ?
                <>
                    <div className="list tags" style={{direction : i18n.dir()}}>
                        <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>{t("main.tags")}</span>
                     {
                        tags.map(el => {
                            return renderTag(el, onlyFavouriteTags)
                        })
                    }
                    {
                        showSuppliers &&  suppliers.map(el => {
                            return renderSupplier(el, true)
                        })
                    }
                    </div>
                </>
             :
            <>
                {
                    showSuppliers &&
                    (
                        <div className="list suppliers" style={{direction : i18n.dir()}}>
                            <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>{t("main.tags")}</span>
                            {
                                suppliers.map(el => {
                                    return renderSupplier(el, true)
                                })
                            }
                        </div>
                    )
                }
                    <div className="list tags" style={{direction : i18n.dir()}}>
                        <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>{t("main.tags")}</span>
                        {
                            Object.keys(tagsByGroupObj).filter(item => item !== 'All').map(item => {
                                return tagsByGroupObj[item].map((el, index) => (
                                        renderTag(el, onlyFavouriteTags)
                                    ))
                            })
                        }
                        {
                            tagsByGroupObj['All'].map((el, index) => (
                                renderTag(el, onlyFavouriteTags)
                            ))
                        }
                    </div>
            </>
    }

    function renderDeliveryTime(isMobile) {
        const meal = showStoreMeals ? selectedStoreMeal : selectedMeal;
        const currentdeliveryTime = showStoreMeals ? deliveryStoreTime : deliveryTime;
        if (isMobile) {
            return meal && <div className='configuration-meal-delivery-time'>
                <DropdownPanel
                    mealDeliveryTimes={meal.DeliveryTimes || []}
                    onSelectMealDeliveryTime={(time) => {
                        !showStoreMeals ?
                            setDeliveryTime(time) : setDeliveryStoreTime(time);
                    }}
                    searchMealDeliveryTime={currentdeliveryTime}
                    showOverlay={state.dropDownExpanded}
                    showMealDeliveryTimes={true}
                    mobile={true}
                />
            </div>
        }

        return  <>
            {meal && (
                    <div className="list meal-delivery" style={{direction : i18n.dir()}}>
                        <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>
                          {t("main.meal_delivery_time")}
                        </span>
                        {meal.DeliveryTimes?.map((time, index) =>
                            <div
                                onClick={() => {
                                    !showStoreMeals ?
                                        setDeliveryTime(time) : setDeliveryStoreTime(time);
                                }}
                                className={
                                    currentdeliveryTime?.Id === time.Id
                                        ? (i18n.language === 'en' ? "list-item active reverse" : "list-item active")
                                        : (i18n.language === 'en' ? "list-item reverse" : "list-item")
                                }
                                key={time.Id}
                            >
                                <span>{moment(time.From , "hh:mm:ss").format("HH:mm")} - {moment(time.To , "hh:mm:ss").format("HH:mm")}</span>
                            </div>
                        )}
                    </div>
                )}
        </>
    }

    function renderPriceRanges() {
        return (priceRanges.length ?
            <>
                <div>
                <div className="title price ranges">
                <span>{t("main.cost_filter_title")}</span>
                </div>
                <div className="price-item">
                    {priceRanges.map((el, index) => (
                        renderPriceRange(el)
                    ))}
                </div>
                </div>
            </> : <></>
        )
    }

    function renderPriceRange(el) {
        return (
            <div
                onClick={() => selectPriceRange(el)}
                className={
                    state.currentPriceRanges.indexOf(el) !== -1
                        ? "list-price active"
                        : "list-price"
                }
                key={el.PriceRangeID}

            >
                <div className="checkbox">
                    <label>
                        <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.29999 1.20001L3.79999 6.70001L1.29999 4.20001"
                                stroke="#6e1427"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </label>
                    <input
                        readOnly
                        type="checkbox"
                        checked={
                            state.currentPriceRanges.indexOf(el) !== -1 ? true : false
                        }
                    />
                </div>
                {/*<img src={el.IconURL} alt="icon" className="list-inner-icon" />*/}
                {
                    ((el.PriceMin === el.PriceMax) && (el.PriceMin === 0)) ? (
                                <span className='currency-icon'>
                                {t('main.price_range_0')}
                            </span>
                        ) :
                        <>
                            <span>{el.PriceMax + (el.PriceMin ? ("-" + el.PriceMin) : "")}</span>
                            <span className='currency-icon'>
                                {t('main.confirmation.currency')}
                            </span>
                        </>
                }
            </div>
        )
    }

    function renderSupplier(el, onlyFavouriteTags) {
        return (
            <div
                onClick={() => selectSupplier(el.SupplierID, onlyFavouriteTags)}
                className={
                    state.currentSuppliers.indexOf(el.SupplierID) !== -1
                        ? (i18n.language === 'en' ? "list-item active reverse" : "list-item active")
                        : (i18n.language === 'en' ? "list-item reverse" : "list-item")
                }
                key={el.SupplierID}
            >
                <div className="checkbox">
                    <label>
                        <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.29999 1.20001L3.79999 6.70001L1.29999 4.20001"
                                stroke="#6e1427"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </label>
                    <input
                        readOnly
                        type="checkbox"
                        checked={
                            state.currentSuppliers.indexOf(el.SupplierID) !== -1 ? true : false
                        }
                    />
                </div>
                {
                    el.SupplierLogo &&
                        <img src={el.SupplierLogo} alt="icon" className="list-inner-icon"/>
                }
                <span>{el.SupplierName}</span>
            </div>
        )
    }

    function selectSupplier(id, isFavourite) {
        setState(draft => {
            let index = draft.currentSuppliers.indexOf(id)
            if (index !== -1) {
                draft.currentSuppliers.splice(index, 1)
            } else {
                isFavourite ? (draft.currentSuppliers = [id]) : draft.currentSuppliers.push(id)
            }
            draft.currentDishComboAndItems = [];
            draft.currentDishComboAndItemsTotal = 0;
        })
    }

    function renderTag(el, onlyFavouriteTags) {
        return (
            <div
                onClick={() => selectTag(el.TagID, onlyFavouriteTags)}
                className={
                    state.currentTags.indexOf(el.TagID) !== -1
                        ? (i18n.language === 'en' ? "list-item active reverse" : "list-item active")
                        : (i18n.language === 'en' ? "list-item reverse" : "list-item")
                }
                key={el.TagID}
            >
                <div className="checkbox">
                    <label>
                        <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.29999 1.20001L3.79999 6.70001L1.29999 4.20001"
                                stroke="#6e1427"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </label>
                    <input
                        readOnly
                        type="checkbox"
                        checked={
                            state.currentTags.indexOf(el.TagID) !== -1 ? true : false
                        }
                    />
                </div>
                <img src={el.IconURL} alt="icon" className="list-inner-icon"/>
                <span>{el.Name}</span>
            </div>
        )
    }

    function renderCalendar() {
        return (
            <div className="input-calendar-wrapper" dir={i18n.dir()}>
                <div className="input-calendar" onClick={() => setShowCalendar(true)}>
                    <input
                        value={
                            selectedRangeForItems ? `${formatDate(selectedRangeForItems.to)}` : ""
                        }
                        className="input-calendar__input"
                        disabled={true}
                    />
                    <img
                        src={calendarIcon}
                        alt="Calendar"
                        className="input-calendar__icon"
                    />
                </div>
                <div ref={calendarWindow}>
                    {showCalendar && (
                        <div className="calendar-wrapper">
                            {<Calendar
                                initialRange={selectedRangeForItems}
                                setSelectedRange={range => {
                                    setSelectedRangeForItems(range)
                                    closeCalendar()
                                    const selectedMeal = props.searchLocation.Meals?.find(
                                        mealInfo => mealInfo.MealType === STORE_TYPE
                                    )
                                    if (selectedMeal) {
                                        updateStoreDishes(selectedMeal, range.from)
                                    }
                                }}
                                isDayForbidden={day => moment(day).isBefore(new Date(), "day")}
                                isSingleSelection={true}
                            />
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }

    function removeLocationHash() {
        var noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL)
    }

    function displayNotification(notificationObjData) {
        setState(draft => {
            draft.pushNotification.show = true;
            draft.pushNotification.imageUrl = notificationObjData.Image;
        })
        const timer = setTimeout(() => {
            setState(draft => {
                draft.pushNotification.show = false;
                draft.pushNotification.imageUrl = null;
            });
            clearTimeout(timer)
        }, process.env.PUSH_NOTIFICATION_CLOSE || 10000);

    }

    useEffect(() => {
        if (state.closeOrderTime) {
            let diffTime =
                Date.parse(new Date(state.closeOrderTime)) - Date.parse(new Date())
            let duration = moment.duration(diffTime, "milliseconds")
            let interval = 1000
            setState(draft => {
                draft.countdown =
                    Math.floor(duration.asHours()) +
                    ":" +
                    checkZero(duration.minutes()) +
                    ":" +
                    checkZero(duration.seconds())
            })
            const checkZero = value => (value < 10 ? "0" + value : value)
            const intervalRef = setInterval(function () {
                duration = moment.duration(duration - interval, "milliseconds")
                if (duration.asSeconds() <= 0) {
                    setState(draft => {
                        draft.countdown = ""
                    })
                    clearInterval(intervalRef)
                }

                setState(draft => {
                    draft.countdown =
                        Math.floor(duration.asHours()) +
                        ":" +
                        checkZero(duration.minutes()) +
                        ":" +
                        checkZero(duration.seconds())
                })
            }, interval)

            return () => clearInterval(intervalRef)
        }
    }, [state.closeOrderTime])
    const urlParams = new URLSearchParams(window.location.hash)


    useEffect(() => {
        const notificationObj = localStorage.getItem('notification');
        if (notificationObj) {
            removeLocationHash();
            localStorage.removeItem('notification');
            const notificationObjData = JSON.parse(notificationObj);
            // Type: General and Action Type: Image
            if (+notificationObjData.Type === 1 && +notificationObjData.ActionType === 3) {
                displayNotification(notificationObjData);
                return;
                // Type: Item
            } else if (+notificationObjData.Type === 2) {
                if (+notificationObjData.ActionType === 3) {
                    displayNotification(notificationObjData);
                }
                const cancelToken = axios.CancelToken.source()
                GetUserMealInfoItem(
                    notificationObjData.ItemID,
                    notificationObjData.MealType,
                    cancelToken.token
                )
                    .then(({data}) => {
                        if (data && data.Date) {
                            setState(draft => {
                                draft.pushNotification.date = data.Date;
                                return draft;
                            })
                            pushDate = {
                                date: data.Date,
                                mealType: notificationObjData.MealType,
                                itemID: notificationObjData.ItemID
                            };
                        }
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            console.error(error)
                            props.setError(
                                error,
                                t("main.api_errors.failed_to_get_user_meal_info")
                            )
                        }
                    })
                return () => cancelToken.cancel()
            }
        }
    }, [location])

    return (
        <div className="main-page-content-wrapper">
            <div className={i18n.language === 'en' ? 'main-wrapper-reverse' : 'main-wrapper'}>
                {state.showFilterPanel ? (
                    <div className="main-order-wrapper mobile-only">
                        {renderTagPanel()}
                    </div>
                ) : (
                    <>
                        {state.showImg && (
                            <div className="main-order-wrapper" onClick={() => setState(draft => {
                                draft.showImg = false
                            })}>
                                <img src={state.showImgUrl}/>
                            </div>
                        )
                        }
                        {state.currentItemId ? (
                            <div className="main-order-wrapper">
                                <OrderDishPage
                                    removeItemId={() =>
                                        setState(draft => {
                                            draft.currentItemId = null
                                            draft.currentStoreItem = null
                                        })
                                    }
                                    deliveryTime={deliveryTime}
                                    subsidyLimit={!!state.subsidyLimit}
                                    paymentOptions={
                                        {
                                            dailyBudget: state.dailyBudget,
                                            walletBalance
                                        }
                                    }
                                    goToOrder={() => {
                                        props.history.push("/order")
                                        if (!!state.subsidyLimit) {
                                            const currentItemIndex = state.dates.findIndex(t => t.Date === props.searchDate);
                                            if (currentItemIndex < state.dates.length - 1) {
                                                props.setSearchDate(state.dates[currentItemIndex + 1].Date);
                                            }
                                        }
                                    }}
                                    orderID={state.orderId}
                                    itemID={state.currentItemId}
                                    isStore={state.currentStoreItem}
                                    mealID={
                                        state.currentItem?.MealID ||
                                            props.searchLocation.Meals?.find(
                                                mealInfo => mealInfo.MealType === props.searchMealType
                                            ).id
                                    }
                                    userID={props.searchUser.UserID}
                                    date={props.searchDate}
                                />
                            </div>
                        ) : (
                            state.editItem && (
                                <div className="main-order-wrapper">
                                    <OrderDishPage
                                        editData={state.editItem}
                                        confirmEditOrder={confirmEditOrder}
                                        submitData={editOrder}
                                        subsidyLimit={!!state.subsidyLimit}
                                        deliveryTime={deliveryTime}
                                        removeItemId={() =>
                                            setState(draft => {
                                                draft.editItem = null
                                            })
                                        }
                                        orderID={state.orderId}
                                        goToOrder={() => props.history.push("/order")}
                                        itemID={state.editItem.OrderItemID}
                                        mealID={
                                            props.searchLocation.Meals?.find(
                                                mealInfo => mealInfo.MealType === props.searchMealType
                                            ).id
                                        }
                                        userID={props.searchUser.UserID}
                                        date={props.searchDate}
                                    />
                                </div>
                            )
                        )}
                        {state.currentComboId ? (
                            <div className="main-combo-wrapper main-order-wrapper">
                                <OrderComboDishPage
                                    removeComboId={() =>
                                        setState(draft => {
                                            draft.currentComboId = null
                                            draft.currentCombo = null
                                        })
                                    }
                                    deliveryTime={deliveryTime}
                                    paymentOptions={
                                        {
                                            dailyBudget: state.dailyBudget,
                                            walletBalance
                                        }
                                    }
                                    goToOrder={() => {
                                        props.history.push("/order")
                                        if (!!state.subsidyLimit) {
                                            const currentItemIndex = state.dates.findIndex(t => t.Date === props.searchDate);
                                            if (currentItemIndex < state.dates.length - 1) {
                                                props.setSearchDate(state.dates[currentItemIndex + 1].Date);
                                            }
                                        }
                                    }}
                                    orderID={state.orderId}
                                    comboID={state.currentComboId}
                                    isStore={state.currentStoreCombo}
                                    mealID={
                                        props.searchLocation.Meals?.find(
                                            mealInfo => mealInfo.MealType === props.searchMealType
                                        ).id
                                    }
                                    userID={props.searchUser.UserID}
                                    date={props.searchDate}
                                />
                            </div>
                        ) : (
                            state.editCombo && (
                                <div className="main-combo-wrapper main-order-wrapper">
                                    <OrderComboDishPage
                                        editData={state.editCombo}
                                        confirmEditOrder={confirmEditComboOrder}
                                        submitData={editComboOrder}
                                        deliveryTime={deliveryTime}
                                        removeComboId={() =>
                                            setState(draft => {
                                                draft.editCombo = null
                                            })
                                        }
                                        orderID={state.orderId}
                                        goToOrder={() => props.history.push("/order")}
                                        comboID={state.editCombo.OrderComboID}
                                        mealID={
                                            props.searchLocation.Meals?.find(
                                                mealInfo => mealInfo.MealType === props.searchMealType
                                            ).id
                                        }
                                        userID={props.searchUser.UserID}
                                        date={props.searchDate}
                                    />
                                </div>
                            )
                        )}
                        <ConfirmationWindow
                            show={state.cancelOrderConfirmation.show}
                            icon={questionOctagonIcon}
                            primaryText={t("order_item.cancel_confirmation.primary")}
                            secondaryText={t("order_item.cancel_confirmation.secondary")}
                            confirmText={t("order_item.cancel_confirmation.continue")}
                            cancelText={t("order_item.cancel_confirmation.cancel")}
                            onConfirm={() =>
                                setState(draft => {
                                    draft.cancelOrderConfirmation.show = false
                                })
                            }
                            onCancel={() => {
                                setState(draft => {
                                    draft.cancelOrderConfirmation.show = false
                                })
                                cancelOrder(
                                    state.cancelOrderConfirmation.OrderId,
                                    state.cancelOrderConfirmation.OrderItemID,
                                    state.cancelOrderConfirmation.OrderComboID
                                )
                            }}
                            onClose={() =>
                                setState(draft => {
                                    draft.cancelOrderConfirmation.show = false
                                })
                            }
                        />
                        <PushWindow show={state.pushNotification.show}
                                    onClose={() =>
                                        setState(draft => {
                                            draft.pushNotification.show = false;
                                            draft.pushNotification.imageUrl = null;
                                        })
                                    }
                                    imageUrl={state.pushNotification.imageUrl}
                        />
                        {showBalance &&
                          <div className="display-amount-block mobile-only">
                            {state.dailyBudget !== null &&
                              <div className="display-amount-block__item"><span>{t("order.payment_options.employer_budget")}</span><span className="display-amount-block__item-amount">₪{state.dailyBudget}</span></div>
                            }
                            {walletBalance !== null &&
                              <div className="display-amount-block__item"><span>{t("order.payment_options.dish_wallet")}</span><span className="display-amount-block__item-amount">₪{walletBalance}</span></div>
                            }
                          </div>
                        }
                        <div className={i18n.language === 'en' ? 'configurations-reverse configurations' : 'configurations'}>
                            {
                                (window.innerWidth < 1024 && banners.length > 0) && (
                                    <Carousel className="bannersCarouselMobile">
                                        {banners.map((banner, index) => (
                                            banner.show &&
                                            <Carousel.Item onClick={() => onClickMobileItem(index)}>
                                                <Carousel.Caption>
                                                    <h5>{banner.title}</h5>
                                                    <p>{banner.description}</p>
                                                </Carousel.Caption>
                                                <img
                                                    className="d-block w-100"
                                                    src={banner.mobileImageUrl}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                )
                            }
                            <DropdownPanel
                                users={state.users}
                                locations={state.locations}
                                onSelectUser={selectUser}
                                onSelectLocation={selectLocation}
                                showOverlay={state.dropDownExpanded}
                                showLocations={true}
                                mobile={true}
                            />
                            {(
                                <>
                                    <div className="configuration calendar">
                                        <div
                                            className={
                                                state.datesExpanded ? "drop-down expanded" : "drop-down"
                                            }
                                        >
                                            <a onClick={() => handleExpand("date")}>
                                                {state.datesExpanded
                                                    ? t("main.select_order_date")
                                                    : formatDate(props.searchDate)}
                                            </a>
                                            <ul>
                                                {state.dates.map((el, index) => (
                                                    <div
                                                        className={`list-calendars-tags-wrapper ${props.searchDate === el.Date
                                                            ? (i18n.language === 'en' ? "list-item active reverse" : "list-item active")
                                                            : (i18n.language === 'en' ? "list-item reverse" : "list-item")}`
                                                        }
                                                        onClick={() => selectDateWithObj(el.Date)}
                                                        key={el.Date}
                                                    >
                                                        <li>
                                                            <a>{formatDate(el.Date)}</a>
                                                        </li>
                                                        <div className="list-calendars-tags">
                                                            {el.Breakfast ? (
                                                                <div className="list-calendars-tag">
                                  <span>
                                    {t("order_item.meal_type.breakfast")}
                                  </span>
                                                                    <img src={tagV}></img>
                                                                </div>
                                                            ) : null}
                                                            {el.Luanch ? (
                                                                <div className="list-calendars-tag">
                                                                    <span>{t("order_item.meal_type.lunch")}</span>
                                                                    <img src={tagV}></img>
                                                                </div>
                                                            ) : null}
                                                            {el.Dinner ? (
                                                                <div className="list-calendars-tag">
                                  <span>
                                    {t("order_item.meal_type.dinner")}
                                  </span>
                                                                    <img src={tagV}></img>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    {
                                        isMobile() && renderDeliveryTime(isMobile())
                                    }

                                    <div className="mobile-only" dir={i18n.dir()}>
                                        {props.searchLocation &&
                                        props.searchLocation.Meals?.length > 1 && !showStoreMeals ? (
                                            <Tabs
                                                tabs={mealTypes
                                                    .filter(
                                                        (el, index) =>
                                                            props.searchLocation &&
                                                            props.searchLocation.Meals?.find(
                                                                mealInfo => mealInfo.MealType === el.ItemId
                                                            ) !== undefined
                                                    )
                                                    .map((el, index) => {
                                                        return el.name
                                                    })}
                                                activeTab={
                                                    mealTypes.find(
                                                        (el, index) => el.ItemId === props.searchMealType
                                                    ).name
                                                }
                                                setActiveTab={tab =>
                                                    selectMeal(
                                                        mealTypes.find((el, index) => el.name === tab)
                                                            .ItemId
                                                    )
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </>
                            )}
                            {!showStoreMeals && showCountdown && (
                                    <div className={i18n.language === 'en' ? 'order-countdown-reverse mobile-only' : 'order-countdown mobile-only'}>
                                    {renderCountdown()}
                                </div>
                            )}
                            <div className={i18n.language === 'en' ? 'search search-reverse' : 'search'}>
                                <input
                                    className="search-field"
                                    value={props.searchText}
                                    onChange={handleSearchTextChange}
                                />
                                <a
                                    className="settings-button"
                                    onClick={() =>
                                        setState(draft => {
                                            draft.showFilterPanel = true
                                        })
                                    }
                                ></a>
                            </div>
                            {(
                                <>
                                    {showBalance &&
                                      <div className="list desktop-only">
                                        {walletBalance !== null &&
                                          <div className="list-item  display-amount-block__item">
                                            <span>{t("order.payment_options.dish_wallet")}</span><span className="display-amount-block__item-amount">₪{walletBalance}</span>
                                          </div>
                                        }
                                        {state.dailyBudget !== null &&
                                          <div className="list-item display-amount-block__item">
                                            <span>{t("order.payment_options.employer_budget")}</span><span className="display-amount-block__item-amount">₪{state.dailyBudget}</span>
                                          </div>
                                        }
                                      </div>
                                    }
                                    <div className="list calendars" style={{direction : i18n.dir()}}>
                                    <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>{t("main.date")}</span>
                                        {state.dates.map((el, index) => (
                                            <div
                                                className="list-calendars-tags-wrapper"
                                                key={el.Date}
                                            >
                                                <div
                                                    onClick={() => selectDateWithObj(el.Date)}
                                                    className={
                                                        props.searchDate === el.Date
                                                            ? (i18n.language === 'en' ? "list-item active reverse" : "list-item active")
                                                            : (i18n.language === 'en' ? "list-item reverse" : "list-item")
                                                    }
                                                >
                                                    <span>{formatDate(el.Date)}</span>
                                                </div>
                                                <div className="list-calendars-tags" >
                                                    {el.Breakfast ? (
                                                        <div className="list-calendars-tag">
                                                            <span>{t("order_item.meal_type.breakfast")}</span>
                                                            <img src={tagV}></img>
                                                        </div>
                                                    ) : null}
                                                    {el.Luanch ? (
                                                        <div className="list-calendars-tag">
                                                            <span>{t("order_item.meal_type.lunch")}</span>
                                                            <img src={tagV}></img>
                                                        </div>
                                                    ) : null}
                                                    {el.Dinner ? (
                                                        <div className="list-calendars-tag">
                                                            <span>{t("order_item.meal_type.dinner")}</span>
                                                            <img src={tagV}></img>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                    {props.searchLocation &&
                                        props.searchLocation.Meals?.length > 1 && !showStoreMeals && (
                                            <div className="list meal-type">
                                                <span className={"list-title" + (i18n.language === 'en' ? " reverse" : "")}>
                                                  {t("main.meal_type")}
                                                </span>
                                                {mealTypes.map((el, index) =>
                                                    {
                                                        const mealItem = props.searchLocation.Meals?.find(
                                                            mealInfo => mealInfo.MealType === el.ItemId
                                                        )
                                                        return mealItem!== undefined ? (
                                                            <div
                                                                onClick={() => {
                                                                    selectMeal(el.ItemId)
                                                                    setSelectedMeal(mealItem);
                                                                }}
                                                                className={
                                                                    props.searchMealType === el.ItemId
                                                                        ? (i18n.language === 'en' ? "list-item active reverse mealType-reverse" : "list-item active")
                                                                        : (i18n.language === 'en' ? "list-item reverse mealType-reverse" : "list-item")
                                                                }
                                                                key={el.ItemId}
                                                            >
                                                                <img
                                                                    src={el.icon}
                                                                    alt="icon"
                                                                    className="list-inner-icon"
                                                                />
                                                                <span>{el.name}</span>
                                                            </div>
                                                        ) : null
                                                    }
                                                )}
                                            </div>
                                    )}
                                </>
                            )}
                            {!isMobile() && renderDeliveryTime()}
                            {renderPriceRanges(width < 1024)}
                            {width >= 1024 && renderSuppliers(width < 1024)}
                            {renderFilters(width < 1024, width < 1024)}
                        </div>
                        <div
                            className={
                                state.currentItemId || state.editItem
                                    ? "main-content-wrapper hidden-on-mobile"
                                    : "main-content-wrapper"
                            }
                        >
                            <div className={i18n.language === 'en' ? 'configurations-reverse configurations' : 'configurations'}>
                                <DropdownPanel
                                    users={state.users}
                                    locations={state.locations}
                                    onSelectUser={selectUser}
                                    onSelectLocation={selectLocation}
                                    showLocations={true}
                                />
                            </div>
                            {
                                (window.innerWidth > 1023 && banners.length > 0) && (
                                    <Carousel className="deskTopBanner">
                                        {banners.map((banner, index) => (
                                            banner.show &&
                                            <Carousel.Item onClick={() => onClickItem(index)} key={index}>
                                                <Carousel.Caption>
                                                    <h5>{banner.title}</h5>
                                                    <p>{banner.description}</p>
                                                </Carousel.Caption>
                                                <img
                                                    className="d-block w-100"
                                                    src={banner.bannerImageUrl}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                )
                            }
                            <div className={i18n.language === 'en' ? 'dishes-list-title-wrapper-reverse' : 'dishes-list-title-wrapper'}>
                                <div className={i18n.language === 'en' ? 'dishes-list-title-reverse' : 'dishes-list-title'}>
                                    {t("main.title")} <span>{t("main.after_title")}</span>
                                </div>
                                {!showStoreMeals && (
                                    <>
                                        {showCountdown && (
                                                <div className={i18n.language === 'en' ? 'order-countdown-reverse desktop-only' : 'order-countdown desktop-only'}>
                                                {renderCountdown()}
                                            </div>
                                        )}
                                        {state.userOrder && state.userOrder.length !== 0 && (
                                            <div className="ordered-item-row" dir={i18n.dir()}>
                                                {state.userOrder.map((el, index) => {
                                                    const closeEditTimeOverElement =
                                                        el.CloseEditTime &&
                                                            Date.parse(new Date(el.CloseEditTime)) - Date.parse(new Date()) <= 0
                                                    const closeCancelTimeOverElement =
                                                        state.CloseCancelTime &&
                                                        Date.parse(new Date(state.CloseCancelTime)) - Date.parse(new Date()) <= 0
                                                    return (
                                                        <div
                                                            className="ordered-item-block"
                                                            dir={i18n.dir()}
                                                            key={el.OrderId + index}
                                                        >
                                                            <div className="ordered-item-block-image">
                                                                <img
                                                                    src={
                                                                        el.ItemImages && el.ItemImages.length
                                                                            ? el.ItemImages[0].URL
                                                                            : imageFiller
                                                                    }
                                                                    alt="Dish image"
                                                                />
                                                            </div>
                                                            <div className="ordered-item-block-info">
                                                                <div className="ordered-item-block-name">
                                                                    {el.OrderItemName}
                                                                </div>
                                                                <div className="ordered-item-block-location">
                                                                    {el.LocationName}
                                                                </div>
                                                                <div className="ordered-item-block-location">
                                                                    <span className='ordered-item-block-price_info-title'>
                                                                        {el.SubsidyAmount === 0 && <>{t("order.total")}: {el.TotalCost} <br /></>}
                                                                        {el.CostumerCreditAmount !== 0 && <>{t("main.confirmation.customerCredit")}: {el.CostumerCreditAmount} <br /></>}
                                                                        {el.CreditCardAmount !== 0 && <>{t("main.confirmation.creditCard")}: {el.CreditCardAmount} <br /></>}
                                                                        {el.BudgetAmount !== 0 && <>{t("main.confirmation.budget")}: {el.BudgetAmount} <br /></>}
                                                                        {el.WalletAmount !== 0 && <>{t("main.confirmation.wallet")}: {el.WalletAmount} <br /></>}
                                                                    </span>
                                                                </div>
                                                                {
                                                                    el.DeliveryTime && (
                                                                        <div className="ordered-item-block-delivery-time">
                                                                           <span className='ordered-item-block-delivery-time-title'>
                                                                                {t('main.meal_order_delivery_time')}
                                                                            </span>
                                                                            <span>{moment(el.DeliveryTime.From , "hh:mm:ss").format("HH:mm")} - {moment(el.DeliveryTime.To , "hh:mm:ss").format("HH:mm")}</span>

                                                                        </div>
                                                                    )
                                                                }
                                                                {(!closeEditTimeOverElement || !closeCancelTimeOverElement) && (
                                                                    <div className="ordered-item-block-actions">
                                                                        {!closeEditTimeOverElement && (
                                                                            <div
                                                                                onClick={() => {
                                                                                    if (el.OrderComboID) {
                                                                                        setState(draft => {
                                                                                            draft.editCombo = el
                                                                                            draft.scrollPosition = window.pageYOffset
                                                                                        })
                                                                                    } else {
                                                                                        setState(draft => {
                                                                                            draft.editItem = el
                                                                                            draft.scrollPosition = window.pageYOffset
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {t("main.ordered_item.edit_btn")}
                                                                                <img src={editIcon} alt="edit"/>
                                                                            </div>
                                                                        )}
                                                                        {!closeEditTimeOverElement && !closeCancelTimeOverElement && (
                                                                            <div/>
                                                                        )}
                                                                        {!closeCancelTimeOverElement && (
                                                                            <div
                                                                                onClick={() =>
                                                                                    setState(draft => {
                                                                                        draft.cancelOrderConfirmation = {
                                                                                            show: true,
                                                                                            OrderId: el.OrderId,
                                                                                            OrderItemID: el.OrderItemID,
                                                                                            OrderComboID: el.OrderComboID
                                                                                        }
                                                                                    })
                                                                                }
                                                                            >
                                                                                {t("main.ordered_item.cancel_btn")}
                                                                                <img src={deleteIcon} alt="delete"/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="ordered-item-block-status">
                                                                <img src={selectedIconRed} alt="Selected"/>
                                                                <div>{t("main.ordered_item.status.ordered")}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            {showStoreMeals && !state.isStoreLoading && showCountdownStore && (
                                <div className={i18n.language === 'en' ? 'order-countdown-reverse desktop-only order-countdown-store' : 'order-countdown desktop-only order-countdown-store'}>
                                    {renderCountdown(true)}
                                </div>
                            )}
                                <div className={i18n.language === 'en' ? 'dishes-list-wrapper-reverse' : 'dishes-list-wrapper'}>
                                {
                                    showStoreMeals ? (
                                        state.isStoreLoading ? (
                                            <LoadingPage/>
                                        ) : state.storeDishes.length ? (
                                            filterDishes(state.storeDishes).map((el, index) => {
                                                const orderDisabled =
                                                    isOrderTimeOverStore ||
                                                    (moment(el.CloseOrderTime).format("HH:mm:ss") !==
                                                        "00:00:00" &&
                                                        moment(el.CloseOrderTime).isBefore())
                                                return <Dish
                                                    isOrderTimeOver={orderDisabled}
                                                    name={el.ItemName}
                                                    restaurant={el.SupplierName}
                                                    img={el.ItemImages ?? []}
                                                    cost={el.Price}
                                                    budget={state.dailyBudget}
                                                    clientPrice={el.ClientPrice}
                                                    customerPrice={el.CustomerPrice}
                                                    isFirstPrice={state.userOrder.length === 0}
                                                    isPriceDisplayed={state.isPriceDisplayed}
                                                    isSubsidy={el.IsSubsidy}
                                                    tags={el.Tags}
                                                    imageTags={el.ImageTags.map(tag => tag.Name)}
                                                    id={el.ItemId}
                                                    supplierName={el.SupplierName}
                                                    key={index}
                                                    isStoreItem={true}
                                                    saveToCart={(itemID, itemName, quantity, cost) =>
                                                        addStoreItemToCart(itemID, itemName, quantity, cost)
                                                    }
                                                    favoriteItem={() => {
                                                        let allFavoriteItems = [];
                                                        let favoriteIsChecked = false;
                                                        if (localStorage.getItem("favoriteItems") != (null || "null")) {
                                                            allFavoriteItems = JSON.parse(localStorage.getItem("favoriteItems"));

                                                            if (allFavoriteItems) allFavoriteItems.map((item, key) => {
                                                                if (item == el.ItemId) {
                                                                    allFavoriteItems.splice(key, 1);
                                                                    favoriteIsChecked = true
                                                                }
                                                            });
                                                        }

                                                        if (!favoriteIsChecked) {
                                                            allFavoriteItems.push(el.ItemId);
                                                        }

                                                        localStorage.setItem("favoriteItems", JSON.stringify(allFavoriteItems));
                                                        setFavoriteItems(JSON.parse(localStorage.getItem("favoriteItems")));
                                                        let data = {
                                                            "FavouriteItems": JSON.stringify(localStorage.getItem("favoriteItems"))
                                                        }
                                                        updateFavorites(data);
                                                    }
                                                    }
                                                />
                                            })
                                        ) : (
                                            <NoResultsFiller
                                                message={t("main.no_products")}
                                                hideIcon={true}
                                            />
                                        )
                                    ) : state.isLoading ? (
                                        <LoadingPage/>
                                            ) : (state.dishes.length || state.dishesCombos.length) ? (
                                                    <>
                                            {
                                                state.currentDishComboAndItems.map(el => {
                                                    if (el.ComboId) {
                                                        const orderDisabled =
                                                            isOrderTimeOver ||
                                                            (moment(el.CloseOrderTime).format("HH:mm:ss") !==
                                                                "00:00:00" &&
                                                                moment(el.CloseOrderTime).isBefore());
                                                        return <ComboDish
                                                            isOrderTimeOver={orderDisabled}
                                                            selectItem={() => {
                                                                if (!orderDisabled) {
                                                                    setState((draft) => {
                                                                        draft.currentComboId = el.ComboId;
                                                                    })
                                                                }
                                                            }}
                                                            name={el.ComboName}
                                                            img={el.ItemImages ?? []}
                                                            tags={el.Tags}
                                                            cost={el.Price}
                                                            clientPrice={el.ClientPrice}
                                                            customerPrice={el.CustomerPrice}
                                                            isFirstPrice={state.userOrder.length === 0}
                                                            id={el.ComboId}
                                                            key={el.ComboId}
                                                            favoriteItem={() => {
                                                                favouriteHandler(el, 'favoriteCombos', 'ComboId')
                                                            }}
                                                        />;
                                                    }
                                                    const orderDisabled =
                                                        isOrderTimeOver ||
                                                        (moment(el.CloseOrderTime).format("HH:mm:ss") !==
                                                            "00:00:00" &&
                                                            moment(el.CloseOrderTime).isBefore())
                                                    return <>
                                                        {
                                                            (
                                                                <Dish
                                                                    isOrderTimeOver={orderDisabled}
                                                                    selectItem={() => {
                                                                        if (!orderDisabled) {
                                                                            openInfoWindow(el.ItemId)
                                                                        }
                                                                    }}
                                                                    subsidyLimit={state.subsidyLimit}
                                                                    name={el.ItemName}
                                                                    restaurant={el.SupplierName}
                                                                    img={el.ItemImages ?? []}
                                                                    tags={el.Tags}
                                                                    budget={state.dailyBudget}
                                                                    isPriceDisplayed={state.isPriceDisplayed}
                                                                    clientPrice={el.ClientPrice}
                                                                    customerPrice={el.CustomerPrice}
                                                                    isSubsidy={el.IsSubsidy}
                                                                    isFirstPrice={state.userOrder.length === 0}
                                                                    cost={el.FirstItemPrice}
                                                                    imageTags={el.ImageTags.map(tag => tag.Name)}
                                                                    id={el.ItemId}
                                                                    key={el.ItemId}
                                                                    favoriteItem={() => {
                                                                        let allFavoriteItems = [];
                                                                        let favoriteIsChecked = false;
                                                                        if (localStorage.getItem("favoriteItems") != (null || "null")) {
                                                                            allFavoriteItems = JSON.parse(localStorage.getItem("favoriteItems"));

                                                                            if (allFavoriteItems) allFavoriteItems.map((item, key) => {
                                                                                if (item == el.ItemId) {
                                                                                    allFavoriteItems.splice(key, 1);
                                                                                    favoriteIsChecked = true
                                                                                }
                                                                            });
                                                                        }

                                                                        if (!favoriteIsChecked && allFavoriteItems) {
                                                                            allFavoriteItems.push(el.ItemId);
                                                                        }
                                                                        if (!allFavoriteItems) {
                                                                            allFavoriteItems = []
                                                                            allFavoriteItems.push(el.ItemId);
                                                                        }

                                                                        localStorage.setItem("favoriteItems", JSON.stringify(allFavoriteItems));
                                                                        setFavoriteItems(JSON.parse(localStorage.getItem("favoriteItems")));
                                                                        let data = {
                                                                            "FavouriteItems": JSON.stringify(localStorage.getItem("favoriteItems"))
                                                                        }
                                                                        updateFavorites(data);
                                                                    }
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    </>
                                                })
                                            }
                                           {/* {
                                                filterComboDishes(state.dishesCombos)
                                                    .map((el, index) => {
                                                        const orderDisabled =
                                                            isOrderTimeOver ||
                                                            (moment(el.CloseOrderTime).format("HH:mm:ss") !==
                                                                "00:00:00" &&
                                                                moment(el.CloseOrderTime).isBefore());
                                                        return <ComboDish
                                                            isOrderTimeOver={orderDisabled}
                                                            selectItem={() => {
                                                                if (!orderDisabled) {
                                                                    setState((draft) => {
                                                                        draft.currentComboId = el.ComboId;
                                                                    })
                                                                }
                                                            }}
                                                            name={el.ComboName}
                                                            img={el.ItemImages ?? []}
                                                            tags={el.Tags}
                                                            cost={el.Price}
                                                            id={el.ComboId}
                                                            key={el.ComboId}
                                                            favoriteItem={() => {
                                                                favouriteHandler(el, 'favoriteCombos', 'ComboId')
                                                            }}
                                                        />;
                                                    })
                                            }
                                            {
                                                filterDishes(state.dishes).map((el, index) => {
                                                    const orderDisabled =
                                                        isOrderTimeOver ||
                                                        (moment(el.CloseOrderTime).format("HH:mm:ss") !==
                                                            "00:00:00" &&
                                                            moment(el.CloseOrderTime).isBefore())
                                                    return <>
                                                        {
                                                            (
                                                                <Dish
                                                                    isOrderTimeOver={orderDisabled}
                                                                    selectItem={() => {
                                                                        if (!orderDisabled) {
                                                                            openInfoWindow(el.ItemId)
                                                                        }
                                                                    }}
                                                                    subsidyLimit={state.subsidyLimit}
                                                                    name={el.ItemName}
                                                                    restaurant={el.SupplierName}
                                                                    img={el.ItemImages ?? []}
                                                                    tags={el.Tags}
                                                                    isPriceDisplayed={state.isPriceDisplayed}
                                                                    cost={el.FirstItemPrice}
                                                                    imageTags={el.ImageTags.map(tag => tag.Name)}
                                                                    id={el.ItemId}
                                                                    key={index}
                                                                    favoriteItem={() => {
                                                                        let allFavoriteItems = [];
                                                                        let favoriteIsChecked = false;
                                                                        if (localStorage.getItem("favoriteItems") != (null || "null")) {
                                                                            allFavoriteItems = JSON.parse(localStorage.getItem("favoriteItems"));

                                                                            if (allFavoriteItems) allFavoriteItems.map((item, key) => {
                                                                                if (item == el.ItemId) {
                                                                                    allFavoriteItems.splice(key, 1);
                                                                                    favoriteIsChecked = true
                                                                                }
                                                                            });
                                                                        }

                                                                        if (!favoriteIsChecked && allFavoriteItems) {
                                                                            allFavoriteItems.push(el.ItemId);
                                                                        }
                                                                        if (!allFavoriteItems) {
                                                                            allFavoriteItems = []
                                                                            allFavoriteItems.push(el.ItemId);
                                                                        }

                                                                        localStorage.setItem("favoriteItems", JSON.stringify(allFavoriteItems));
                                                                        setFavoriteItems(JSON.parse(localStorage.getItem("favoriteItems")));
                                                                        let data = {
                                                                            "FavouriteItems": JSON.stringify(localStorage.getItem("favoriteItems"))
                                                                        }
                                                                        updateFavorites(data);
                                                                    }
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    </>
                                                })
                                            }*/}
                                        </>
                                    ) : (
                                        !!state.specialDate ?
                                            <MessageCard message={
                                                state.specialDate.Name
                                            }/> : (

                                                !!state.subsidyLimit
                                                    ?
                                                    <NoResultsFiller
                                                        message={
                                                            t("main.subsidy_limit")
                                                        }
                                                    />
                                                    : (
                                                        <NoResultsFiller
                                                            message={
                                                                props.searchLocation.Meals?.length
                                                                    ? t("main.no_results")
                                                                    : t("main.no_meals")
                                                            }
                                                        />
                                                    )


                                        )
                                    )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {width < 1024 ? <SummaryMobile searchUser={props.searchUser} handleRedirectToOrders={handleRedirectToOrders}></SummaryMobile>: <></>}
            {width < 1024 ? <Footer/> : <LogoFooter/>}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.login.userInfo,
        searchText: state.search.searchText,
        searchDate: state.search.searchDate,
        searchUser: state.search.searchUser,
        searchLocation: state.search.searchLocation,
        searchMealType: state.search.searchMealType,
        cart: state.cart,
        adminUsers: state.admin.users,
        selectedCustomer: state.admin.selectedCustomer,
        adminCustomers: state.admin.customers,
    }
}

const mapDispatchToProps = {
    saveTempItemToOrder,
    incCount,
    setSearchText,
    setSearchDate,
    setSearchUser,
    setSearchLocation,
    setSearchMealType,
    setSearchConfiguration,
    setError,
    setSuccess
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainPage)
)
